.footer-section {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-shadow: 0px -1px 3px rgba(164, 164, 164, 0.75);
  -moz-box-shadow: 0px -1px 3px rgba(164, 164, 164, 0.75);
  box-shadow: 0px -1px 3px rgba(164, 164, 164, 0.75);

  .logo-holder {
    color: #03A9F4;
    // color: gray;
    font-size: 27px;
    font-weight: 300;
    display: inline-flex;

    .img-logo {
      width: 60px;
      height: 60px;
      margin-right: 5px;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }

    .img-name {
      line-height: 60px;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 40px;
      }
    }
  }

  .links-holder {
    margin-top: 20px;

    a {
      margin: 6px;
      color: gray;
    }
  }

  .social-media-links {
    margin-top: 13px;
    .social-icon {
      // color: #03A9F4;
      color: gray;
    }
  }

  .all-rights-reserved {
    margin-top: 7px;
    color: gray;
  }
  
}
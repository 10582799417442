
.subscription-dialog-container {

  .sub-icon {
    color: #03A9F4 !important;
  }

  .offer-pricetag {
    color: #03A9F4;
  }

  .dialog-content {
    padding: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-container {
    border-right: solid 1px rgba($color: #000000, $alpha: .3);

    .auth-banner-heading {
      font-size: 35px;
      font-weight: 700;
      line-height: initial;
    }

    .banner-img-container {
      padding-right: 40px;
      margin-top: 50px;
  
      img {
        width: 100%;
      }
    }
  }

  .subscription-form-container {
    padding-left: 20px;
    padding-right: 20px;

    .auth-heading {
      margin-top: 20px;
      font-size: 35px;
      font-weight: 700;
      line-height: initial;
      text-align: center;
    }

    .auth-slogan {
      margin-top: 40px;
      text-align: center;
      font-size: 20px;
    }
    
    .mobile-number {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }

    .login-btn {
      display: block;
      margin: auto;
      margin-top: 10px;
      width: 80%      
    }

    .resend-otp-container {
      display: flex;
      justify-content: space-between;
      padding: 0px 38px;
      margin-top: 3px;

      .otp-timer {
        font-size: 14px;
        color: #03A9F4;
        margin-top: 3px;
      }

      .resend-otp-btn {
        padding: 0px 7px;
      }
    }

    .subscription-success {
      margin-top: 40%;
      margin-bottom: 20px;
      
      .subscription-success-icon {
        font-size: 36px;
      }

      .subscription-success-message {
        font-size: 25px;
      }
    }
  }
  
  .captcha-container {
    display: flex;
    justify-content: center;
  }

  .otpContainer {
    margin: 5% auto;
    display: flex;
    justify-content: center;
  }
  
  .otpInput {
    width: 37px !important;
    height: 37px;
    margin: 0 4px;
    font-size: 20px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
}


@media screen and (max-width: 425px) {
  .subscription-dialog-container {
    max-width: 100%;
    width: 100%;
    .dialog-content {
      padding: 20px;
      .grid-container {
        margin: 0px;
        justify-content: center;
      }
    }

    .banner-container {
      border-right: none;

      .auth-banner-heading {
        font-size: 25px;
        text-align: center;
      }
      .banner-img-container {
        margin-top: 10px;
        padding-right: 0px;
        text-align: center;
        img {
          width: 200px;
          margin: auto;
        }
      }
    }

    .subscription-form-container {
      margin: 0px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      .mobile-number {
        width: 100%;
        margin-top: 0px;
        font-size: 25px;

        label {
          font-size: 12px;
        }
      }
      .auth-heading {
        font-size: 27px;
        margin-top: 20px;
      }

      .auth-slogan{
        margin-top: 10px;
      }

      .resend-otp-container {
        padding: 0px;
        display: block;
        width: 80%;
        height: 20px;
        margin-left: 10%;
  
        .otp-timer {
          font-size: 10px;
          color: #03A9F4;
          margin-top: 3px;
          float: left;
        }
  
        .resend-otp-btn {
          padding: 0px 7px;
          font-size: 10px;
          float: right;
        }
      }

      .subscription-success {
        margin-top: 0px;
      }
    }

    .captcha-container {
      transform: scale(0.8);
    }
  }
}
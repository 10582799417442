.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.responsive-menu-btn {
  margin-right: 0px !important;
}

@media screen and (min-width: 1024px) {
  .responsive-menu-btn {
  }
}

@media screen and (max-width: 1024px) {
  .navigation-links-container {
    display: none !important;
  }
}




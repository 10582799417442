.cke_chrome {
  border: none !important;
}

.cke_top, .cke_bottom {
  display: none !important;
}

.cke_contents {
  height: auto !important;
}

.cke_editable {
  margin: 0 !important;
  font-size: 20px !important;
  font-weight: 400;
}

.pointer .cke_editable {
  cursor: pointer !important;
}
.loader-container {
  display: none;
}



.loader-left .loader-overlay {
  position: absolute;
  width: 50%;
  z-index: 500000;
  top: 0;
  left: 0;
}

.loader-right .loader-overlay {
  position: absolute;
  width: 50%;
  z-index: 500000;
  top: 0;
  right: 0;
}

.loader {
  height: 2px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #FFF;
}

.loader-right  .loader:before {
  display: block;
  position: absolute;
  content: "";
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: #F44E03;
  animation: loading_right 1s linear infinite;
  animation-direction: alternate;
}

.loader-left .loader:before {
  display: block;
  position: absolute;
  content: "";
  right: 0px ;
  width: 100%;
  height: 2px;
  background-color: #F44E03;
  animation: loading_left 1s linear infinite;
  animation-direction: alternate;
}


@keyframes loading_right {
  from {
      width: 30%;
  }
  50% {
      width: 60%;
  }
  70% {
      width: 70%;
  }
  80% {
      width: 80%;
  }
  90% {
      width: 90%;
  }
  to {
      width: 100%;
  }
}

@keyframes loading_left {
  from {
      width: 30%;
  }
  50% {
      width: 60%;
  }
  70% {
      width: 70%;
  }
  80% {
      width: 80%;
  }
  90% {
      width: 90%;
  }
  to {
      width: 100%;
  }
}
